import * as React from "react";

const SvgComponent = (props) => (
  <svg
    viewBox="420.1 80.7 1079.8 1758.6"
    width={30}
    height={30}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M959.8 80.7 420.1 976.3 959.8 731z" fill="#8a92b2" />
    <path
      d="M959.8 731 420.1 976.3l539.7 319.1zm539.8 245.3L959.8 80.7V731z"
      fill="#62688f"
    />
    <path d="m959.8 1295.4 539.8-319.1L959.8 731z" fill="#454a75" />
    <path d="m420.1 1078.7 539.7 760.6v-441.7z" fill="#8a92b2" />
    <path d="M959.8 1397.6v441.7l540.1-760.6z" fill="#62688f" />
  </svg>
);

export default SvgComponent;
