import React from "react";
import { FaShare } from "react-icons/fa";
import { useState } from "react";
import { Group, Tooltip, Text} from "@mantine/core";
import ShareJobModal from "./ShareJobModal";
import { iconTheme } from "../../Utils/theme";

const ShareSocials = ({ title, jobID, size, shareMyJob }) => {
  const [opened, setOpened] = useState(false);

  return (
    <>
      <ShareJobModal
        opened={opened}
        setOpened={setOpened}
        title={title}
        jobID={jobID}
      />
      <Group position="center">
        <Tooltip label="Share this job">
          <Group spacing="xs">
            <FaShare
              style={{ cursor: "pointer" }}
              sx={iconTheme}
              onClick={() => setOpened(true)}
              size={size}
              strokeWidth={1.5}
            />
            {shareMyJob && (
              <Text
                style={{ cursor: "pointer" }}
                size="lg"
                weight={600}
                onClick={() => setOpened(true)}
              >
                Share
              </Text>
            )}
          </Group>
        </Tooltip>
      </Group>
    </>
  );
};

export default ShareSocials;
