import React, { useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import { Link, useHistory } from "react-router-dom";
import {
  Button,
  Input,
  InputWrapper,
  Paper,
  PasswordInput,
  Text,
} from "@mantine/core";
import Footer from "../components/Footer";
import { PageChangeContainer } from "../components/RouteComponents/PageChangeContainer";

export default function Signup() {
  const { signup } = useAuth();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const [userName, setUserName] = useState();
  const [userNameError, setUserNameError] = useState();

  const [email, setEmail] = useState();
  const [emailError, setEmailError] = useState();

  const [password, setPassword] = useState();
  const [passwordConfirm, setPasswordConfirm] = useState();
  const [passwordError, setPasswordError] = useState();

  async function handleSubmit(e) {
    e.preventDefault();

    setUserNameError("");
    setEmailError("");
    setPasswordError("");

    if (!userName) {
      setUserNameError("Please enter a user name");
      return;
    }
    if (!password) {
      setPasswordError("Please enter a password");
      return;
    }
    if (password !== passwordConfirm) {
      return setPasswordError("Passwords do not match");
    }
    try {
      setLoading(true);
      await signup(userName, email, password, passwordConfirm);
      history.push("/search-job:uuid");
    } catch (e) {
    
      const userNameError = e?.response?.data?.errors?.name ?? "An unexpected error occurred. Please try again.";
      const emailError = e?.response?.data?.errors?.email ?? "An unexpected error occurred. Please try again.";

      if (userNameError !== "An unexpected error occurred. Please try again.") {
        setUserNameError(userNameError);
      } else if (emailError !== "An unexpected error occurred. Please try again.") {
        setEmailError(emailError);
      } else {
        alert("Signup failed. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <PageChangeContainer
        style={{
          width: 400,
          margin: "auto",
          marginTop: 40,
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <div style={{ flexGrow: 1 }}>
          <Paper radius={12} withBorder p="md" shadow="lg">
            <Text weight={500} size="xl" className="mb-3 text-center">
              Create an Account
            </Text>
            <InputWrapper
              className="mb-3"
              id="user-input"
              required
              label="Username"
              description="Limited to letters, numbers, dashes and underscores"
              error={userNameError}
            >
              <Input
                invalid={userNameError}
                radius={8}
                onChange={(e) => setUserName(e.target.value)}
                id="user-input"
                placeholder="jobsonblocks"
              />
            </InputWrapper>
            <InputWrapper
              className="mb-3"
              id="email-input"
              required
              label="Email"
              error={emailError}
            >
              <Input
                invalid={emailError}
                radius={8}
                onChange={(e) => setEmail(e.target.value)}
                id="email-input"
                placeholder="jobs@onblocks.com"
              />
            </InputWrapper>
            <InputWrapper
              className="mb-4"
              id="password-input"
              required
              label="Password"
              description="Your password must be over 8 characters long"
            >
              <PasswordInput
                error={passwordError}
                radius={8}
                onChange={(e) => setPassword(e.target.value)}
                id="password-input"
                placeholder="Make it Secure!"
              />
              <PasswordInput
                radius={8}
                className="mt-3"
                onChange={(e) => setPasswordConfirm(e.target.value)}
                id="confirm-password-input"
                placeholder="Confirm Password"
              />
            </InputWrapper>
            <Button
              radius={8}
              color="indigo"
              disabled={loading}
              className="w-100"
              onClick={handleSubmit}
            >
              Sign Up
            </Button>
            <div className="w-100 text-center mt-3">
              <Text className="text-center mt-2">
                <Link style={{ color: "#4c6ef5" }} to="/forgot-password">
                  Forgot Password?
                </Link>
              </Text>
            </div>
          </Paper>
          <Text className="text-center mt-3">
            Already have an account?{" "}
            <Link style={{ color: "#4c6ef5" }} to="/login">
              Log In
            </Link>
          </Text>
        </div>
      </PageChangeContainer>
      <div style={{ flex: 1 }}>
        <Footer />
      </div>
    </>
  );
}
