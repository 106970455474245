import React from "react";

import BTC from "../../images/btc.png";
import ETH from "../../images/eth.png";
import DCR from "../../images/dcr.png";
import XMR from "../../images/xmr.png";
import LTC from "../../images/ltc.png";
import BNB from "../../images/bnb.png";
import ADA from "../../images/ada.png";
import XRP from "../../images/xrp.png";
import FIRO from "../../images/firo.png";
import SOL from "../../images/sol.png";
import DOGE from "../../images/doge.png";

import { Avatar, AvatarsGroup, Text, Tooltip } from "@mantine/core";

const CoinJobIcons = ({ job, largerLimit }) => {
  // jobCoins array name will have to be changed to target the right data set
  // console.log(job.currency[0].name);
  const jobCoins = job.currency_type;
  // use jobCoins to find the name in the array that will render the proper coins
  // console.log(jobCoins);
  return (
    <Tooltip
      position="bottom"
      placement="start"
      wrapLines
      withArrow
      transition="pop"
      transitionDuration={200}
      //use job coin data results to render tooltip using {jobCoins} array
      label={<Text size="xs">{jobCoins}</Text>}
      color="gray"
    >
      <AvatarsGroup size={30} limit={largerLimit ? 100 : 2}>
        {jobCoins.includes("Bitcoin") && <Avatar src={BTC} />}
        {jobCoins.includes("Ethereum") && <Avatar src={ETH} />}
        {jobCoins.includes("Ripple") && <Avatar src={XRP} />}
        {jobCoins.includes("Decred") && <Avatar src={DCR} />}
        {jobCoins.includes("Monero") && <Avatar src={XMR} />}
        {jobCoins.includes("Litecoin") && <Avatar src={LTC} />}
        {jobCoins.includes("Binance Smart Chain") && <Avatar src={BNB} />}
        {jobCoins.includes("Cardano") && <Avatar src={ADA} />}
        {jobCoins.includes("Firo") && <Avatar src={FIRO} />}
        {jobCoins.includes("Solana") && <Avatar src={SOL} />}
        {jobCoins.includes("Doge") && <Avatar src={DOGE} />}
        {jobCoins.includes("Negotiable") && <Avatar>Any</Avatar>}
      </AvatarsGroup>
    </Tooltip>
  );
};

export default CoinJobIcons;
