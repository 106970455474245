import { AccountUpdate } from "./AccountUpdate";
import { UserInfo } from "./UserInfo";
import React from "react";
import { Text, Tabs, Container } from "@mantine/core";
import { Checks, Lock, User } from "tabler-icons-react";
import { useMediaQuery } from "@mantine/hooks";

export function AccountNavigation({}) {
  const matches = useMediaQuery("(max-width: 768px)");

  return (
    <>
      <Container px="md" py="xl">
        <Tabs
          orientation={matches ? "horizontal" : "vertical"}
          grow
          color="indigo"
          tabPadding="lg"
        >
          <Tabs.Tab
            className="mb-2"
            label={
              <Text weight={500} size="md">
                User
              </Text>
            }
            icon={<User size={22} />}
          >
            <UserInfo />
          </Tabs.Tab>
          <Tabs.Tab
            className="mb-2"
            label={
              <Text weight={500} size="md">
                Password
              </Text>
            }
            icon={<Lock size={22} />}
          >
            <AccountUpdate />
          </Tabs.Tab>
        </Tabs>
      </Container>
    </>
  );
}
