import {
  Button,
  Chip,
  Chips,
  InputWrapper,
  LoadingOverlay,
  Select,
  Input,
  Divider,
  Paper,
  Loader,
  Container,
  Group,
  Text,
} from "@mantine/core";
import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { coinData, jobData } from "../Utils/Constants";
import { useAuth } from "../contexts/AuthContext";
import { baseURL } from "../Utils/Constants";
import { PageChangeContainer } from "../components/RouteComponents/PageChangeContainer";
import { showNotification } from "@mantine/notifications";
import axios from "axios";
import { useParams } from "react-router-dom";
import { RichTextEditor } from '@mantine/rte';
import Moment from "react-moment";
import DOMPurify from 'dompurify';

const styles = {
  spacing: {
    marginBottom: 12,
  },
};

const UpdateJob = () => {
  const history = useHistory();
  const { userEmail, authAxios } = useAuth();
  const [loading, setLoading] = useState(true);

  const { uuid } = useParams();

  const [jobTitle, setJobTitle] = useState("");

  const [createdDate, setCreatedDate] = useState("");
  const [jobError, setJobError] = useState("");

  const [location, setLocation] = useState("");

  const [category, setCategory] = useState("");
  const [categoryError, setCategoryError] = useState("");

  const [currency, setCurrency] = useState("");

  const [value, setValue] = useState("");
  const [error, setError] = useState("");

  const [employTypechipValue, setEmployTypeChipValue] = useState("");
  const [hireTypechipValue, setHireTypeChipValue] = useState("");
  const [visible, setVisible] = useState(false);

  const sanitizedDescription = DOMPurify.sanitize(value);

  useEffect(() => {
    setError(false);
    setLoading(true);
    setVisible(true);
    const axiosOnRender = async (url) => {
      try {
        const response = await axios(url);
        if (userEmail !== response.data.jobs.user.email) {
          history.push("/my-jobs");
          return;
        }
        setJobTitle(response.data.jobs.title);
        setCurrency(response.data.jobs.currency_type);
        setValue(response.data.jobs.description);
        setCategory(response.data.jobs.category);
        setLocation(response.data.jobs.location);
        setEmployTypeChipValue(response.data.jobs.job_employment_type);
        setHireTypeChipValue(response.data.jobs.job_hire_type);
        setCreatedDate(response.data.jobs.created_at);
      } catch (e) {
        showNotification({
          title: "Job update error 👎",
          message: "There was an error getting your job please try again",
          color: "red",
          radius: 12,
        });
      }
      setLoading(false);
      setVisible(false);
    };
    axiosOnRender(`${baseURL}/jobs/${uuid}`);
  }, [uuid, history, userEmail]);

  const handleSubmit = async () => {
    setJobError("");
    setCategoryError("");
    setError("");
    if (jobTitle === "") {
      setJobError("Please enter a job Title");
      return;
    }
    if (!category) {
      setCategoryError("Please select a category");
      return;
    }
    if (value === "") {
      setError("Please enter your job description ");
      return;
    }

    setVisible(true);
    try {
      await authAxios.put(`${baseURL}/jobs/update/${uuid}`, {
        title: jobTitle,
        currency_type: currency,
        location: location,
        job_employment_type: employTypechipValue,
        job_hire_type: hireTypechipValue,
        category: category,
        description: sanitizedDescription,
      });
      showNotification({
        title: "Job updated 👍",
        message: "Your job was successfully updated",
        color: "green",
        radius: 12,
      });
      history.push("/my-jobs");
    } catch (e) {
      showNotification({
        title: "Job update error 👎",
        message: `${e} Your job was not updated, please try again`,
        color: "red",
        radius: 12,
      });
    }

    setVisible(false);
  };

  return (
    <>
      <LoadingOverlay
        loader={<Loader color="indigo" size="lg" variant="dots" />}
        visible={visible}
      />
      {!loading && (
        <>
          <PageChangeContainer>
            <Container py="xl">
              <Paper radius={12} withBorder p="md" shadow="xl">
                <InputWrapper error={jobError} size="md" label="Job Title" required>
                  <Input
                    radius={8}
                    style={styles.spacing}
                    onChange={(e) => setJobTitle(e.target.value)}
                    placeholder="Job Title"
                    size="md"
                    required
                    value={jobTitle}
                    onPaste={(e) => {
                      e.preventDefault();
                      const text = e.clipboardData.getData('text/plain');
                      setJobTitle(text);
                    }}
                  />
                </InputWrapper>
                <InputWrapper
                  label="Location"
                  size="md"
                >
                  <Input
                    value={location}
                    style={styles.spacing}
                    radius={8}
                    onChange={(e) => setLocation(e.target.value)}
                    placeholder="Optional"
                    label="Location"
                    size="md"
                    onPaste={(e) => {
                      e.preventDefault();
                      const text = e.clipboardData.getData('text/plain');
                      setLocation(text);
                    }}
                  />
                </InputWrapper>
                <InputWrapper
                  error={categoryError}
                  description="Dont see any that match your job? Select other."
                  label="Job Category"
                  size="md"
                  required
                >
                  <Select
                    radius={8}
                    style={styles.spacing}
                    placeholder="Search from pre-filled"
                    data={jobData}
                    searchable
                    size="md"
                    onChange={setCategory}
                    value={category}
                    transitionDuration={150}
                    transition="pop-top-left"
                    transitionTimingFunction="ease"
                  />
                </InputWrapper>
                <InputWrapper error={error} size="md" label="Job Description" required>
                  <RichTextEditor
                    value={value}
                    onChange={setValue}
                    style={{ width: '100%', overflow: 'hidden', overflowWrap: 'break-word', wordBreak: 'break-word'}}
                    controls={[
                      ['bold', 'italic', 'underline'],
                      ['unorderedList', 'orderedList'],
                      ['link'],
                    ]}
                  />
                </InputWrapper>
                <InputWrapper
                  style={(styles.spacing, { marginTop: 12 })}
                  label="Preferred payment currency"
                  size="md"
                >
                  <Select
                    radius={8}
                    data={coinData}
                    onChange={setCurrency}
                    value={currency}
                    searchable
                    size="md"
                    transitionDuration={150}
                    transition="pop-top-left"
                    transitionTimingFunction="ease"
                    placeholder="Not selecting will default to negotiable"
                  />
                </InputWrapper>
                <InputWrapper
                  style={(styles.spacing, { marginTop: 14 })}
                  label="Employment Type"
                  size="md"
                >
                  <Chips
                    color="indigo"
                    variant="filled"
                    size="md"
                    style={{ marginLeft: -3}}
                    radius={12}
                    value={employTypechipValue}
                    onChange={setEmployTypeChipValue}
                  >
                    <Chip value="Fulltime">Full-time</Chip>
                    <Chip value="Parttime">Part-Time</Chip>
                    <Chip value="Onetime">One-Time</Chip>
                  </Chips>


                </InputWrapper>

                <InputWrapper
                  style={(styles.spacing, { marginTop: 14 })}
                  label="Job Hire Type"
                  size="md"
                >
                  <Chips
                    color="indigo"
                    variant="filled"
                    size="md"
                    radius={12}
                    style={{ marginLeft: -3}}
                    value={hireTypechipValue}
                    onChange={setHireTypeChipValue}
                  >
                    <Chip value="Hiring">Hiring</Chip>
                    <Chip value="ForHire">For Hire</Chip>
                  </Chips>


                </InputWrapper>

                <Divider my="md" style={{ borderColor: '#2f2f2f' }} />
              <Group position="apart">
              <Button
                    radius={12}
                    onClick={handleSubmit}
                    color="indigo"
                    size="lg"
                  >
                    Submit
                  </Button>

              <Text>
                  Created on{" "}
                  <Text color="dimmed"><Moment format="YYYY/MM/DD">{createdDate}</Moment></Text>
              </Text>
                
              </Group>
              </Paper>
            </Container>
          </PageChangeContainer>
        </>
      )}
    </>
  );
};

export default UpdateJob;
