import { Button } from "@mantine/core";
import React from "react";
export function ThemedButton({ onKeyDown, children, onClick }) {
  return (
    <Button
      onKeyDown={onKeyDown}
      onClick={onClick}
      color="indigo"
      radius={12}
      size="lg"
      variant="filled"
    >
      {children}
    </Button>
  );
}
