import {
  Button,
  Container,
  Divider,
  Group,
  Loader,
  LoadingOverlay,
  Paper,
  Space,
  Text,
} from "@mantine/core";
import axios from "axios";
import React, { useEffect, useState } from "react";

import Moment from "react-moment";
import { useParams, useHistory } from "react-router-dom";
import Footer from "../components/Footer";
import JobEmploymentTypeBadge from "../components/JobComponents/JobEmploymentTypeBadge";
import ShareSocials from "../components/JobComponents/ShareSocial";
import VerifyedJobLogo from "../components/JobComponents/VerifyedJobLogo";
import { PageChangeContainer } from "../components/RouteComponents/PageChangeContainer";
import CoinJobIcons from "../components/ThemedComponents/CoinJobIcons";
import NotFound from "./NotFound";
import { baseURL } from "../Utils/Constants";
import { RichTextEditor } from '@mantine/rte';

const ViewJob = () => {
  const history = useHistory();
  const { uuid } = useParams();
  const [job, setJob] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setError(false);
    setLoading(true);
    const axiosOnRender = async (url) => {
      try {
        const response = await axios(url);
        if (response.data.jobs === null) {
          setError(true);
          setLoading(false);
          return;
        }
        setJob(response?.data);
      } catch (e) {
        setError(true);
        setLoading(true);
        history.push('/NotFound');
      }
      setLoading(false);
    };
    axiosOnRender(`${baseURL}/jobs/${uuid}`);
  }, [uuid, history]);

  return (
    <>
      <LoadingOverlay
        loader={<Loader color="indigo" size="lg" variant="dots" />}
        visible={loading}
      />
      {error && !loading && (
        <Text align="center" size="xl" pt="xl">
          There was an error finding this job.
        </Text>
      )}
      {!loading && !error && (
        <PageChangeContainer
          style={{
            margin: "auto",
            marginTop: 40,
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
          }}
        >
          <Container>
            <Paper
              radius={12}
              withBorder
              p="md"
              style={{
                minWidth: 600,
                maxWidth: 1200,
                marginBottom: 60,
                borderRadius: 12,
              }}
              className="mx-auto"
              type="auto"
            >
              <Text pb="lg" align="center" size="xl" weight={700}>
                {job?.jobs?.title} <VerifyedJobLogo job={job?.jobs} />
              </Text>
              <Group position="apart">
                <Text size="xl" weight={600}>
                  Job Info:
                </Text>
                <ShareSocials
                  title={job.title}
                  jobID={job.jobs.uuid}
                  size={26}
                  shareMyJob
                />
              </Group>

              <Space h="sm" />
              <Group pb={3} spacing="xs">
                <Text color="gray" weight={500}>
                  User:
                </Text>
                <Text weight={500}>{job?.jobs?.user?.name}</Text>
              </Group>
              <Group pb={3} spacing="xs">
                <Text color="gray" weight={500}>
                  Category:
                </Text>
                <Text weight={500}>{job?.jobs?.category}</Text>
              </Group>
              <Group pb={3} spacing="xs">
                <Text color="gray" weight={500}>
                  Location:
                </Text>
                <Text weight={500}>
                  {!job?.location ? "Remote 🌎" : job?.location}
                </Text>
              </Group>
              <Group pb={3} spacing="xs">
                <Text color="gray" weight={500}>
                  Date posted:
                </Text>
                <Text weight={500}>
                  <Moment format="YYYY/MM/DD">{job?.created_at}</Moment>
                </Text>
              </Group>
              <Group pb={6} spacing="xs">
                <Text color="gray" weight={500}>
                  Desired Coin:
                </Text>
                <Text weight={500}>{job?.jobs?.currency_type}</Text>
                <CoinJobIcons job={job?.jobs} />
              </Group>
              <Group position="apart">
                <JobEmploymentTypeBadge largeSize job={job?.jobs} />
                <Button
                  size="sm"
                  weight={500}
                  color="indigo"
                  radius={8}
                  style={{ color: "white", textDecoration: "none" }}
                  component="a"
                  href={`mailto:${job?.user?.email}?subject=${job.title} job ad`}
                >
                  Apply via email
                </Button>
              </Group>
              <Divider my="sm" />
              <Text size="xl" weight={600}>
                Job Description:
              </Text>
              <Space h="md" />
              <RichTextEditor 
              controls={[
                ['bold', 'italic', 'underline'],
                ['unorderedList', 'orderedList'],
                ['link'],
              ]}
              value={job?.jobs?.description} readOnly={true} />
              <Space h="lg" />
              <Text
                size="md"
                weight={500}
                color="indigo"
                component="a"
                href={`mailto:${job?.jobs?.email}?subject=${job?.jobs?.title} job ad`}
              >
                {job?.jobs?.email}
              </Text>
            </Paper>
          </Container>
        </PageChangeContainer>
      )}
      {!loading && !error && <Footer />}
    </>
  );
};

export default ViewJob;
