import React from "react";
import Job from "./Job";
import JobPagination from "./JobPagination";
import { motion } from "framer-motion";

export function Jobs({
  currentPage,
  setCurrentPage,
  postsPerPage,
  loading,
  data,
  currentJob,
}) {
  return (
    <div style={{ marginBottom: 18 }}>
      {currentJob.map((job) => {
        return (
          <motion.div
            className="mx-auto"
            style={{ maxWidth: 600, cursor: "pointer" }}
            whileHover={{ scale: 1.04 }}
            key={job.uuid}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0, transition: { duration: 0.1 } }}
          >
            {!loading && <Job job={job} />}
          </motion.div>
        );
      })}
      {data.length > postsPerPage && !loading && (
        <JobPagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          postsPerPage={postsPerPage}
          total={Math.ceil(data.length / postsPerPage)}
        />
      )}
    </div>
  );
}
