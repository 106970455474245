import React from "react";
import SearchSection from "../components/SearchSection";
import { PageChangeContainer } from "../components/RouteComponents/PageChangeContainer";

const SearchJob = () => {
  return (
    <>
      <PageChangeContainer>
        {/* <Alert
        icon={"✌️"}
        title="New feature coming soon!"
        color="green"
        radius="md"
        variant="light"
        className="mx-auto"
        style={{
          maxWidth: 600,
          marginTop: 40,
        }}
      >
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iure nulla
        laboriosam pariatur possimus voluptate in officiis.
      </Alert> */}

        <SearchSection />
      </PageChangeContainer>
    </>
  );
};

export default SearchJob;
