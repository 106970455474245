import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { baseURL } from "../Utils/Constants";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const AUTH_TOKEN = currentUser?.data?.token;
  const userName = currentUser?.data?.user?.name;
  const userEmail = currentUser?.data?.user?.email;

  // setting axios to have a bearer token by default if there is an AUTH_TOKEN found.
  const authAxios = axios.create({
    baseURL: baseURL,
    headers: {
      Authorization: `Bearer ${AUTH_TOKEN}`,
    },
  });
  if (AUTH_TOKEN) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${AUTH_TOKEN}`;
  }

  //local storage to know if the access token is still valid
  useEffect(() => {
    const loggedInUser = localStorage.getItem("user");
    const foundUser = JSON.parse(loggedInUser);
    if (loggedInUser) {
      setCurrentUser(foundUser);
    }
    if (!loggedInUser) {
      setCurrentUser(null);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(currentUser));
  }, [currentUser]);

  function signup(name, email, password, password_confirmation) {
    return axios
      .post(`${baseURL}/user/register`, {
        name,
        email,
        password,
        password_confirmation,
      })
      .then((response) => {
        setCurrentUser(response);
      })
      .finally(localStorage.setItem("user", currentUser));
  }

  // auth functions
  function login(email, password) {
    return axios
      .post(`${baseURL}/user/login`, {
        email,
        password,
      })
      .then((response) => {
        setCurrentUser(response);
      })
      .finally(localStorage.setItem("user", currentUser));
  }

  function logout() {
    return authAxios.post("/user/logout").then(setCurrentUser(null));
  }

  function resetPassword(email) {
    return axios.post(`${baseURL}/password/forgot`, { email });
  }

  function updateEmail(email) {
    // return currentUser.updateEmail(email);
  }

  function deleteAccount() {
    return authAxios.post(`${baseURL}/user/delete`);
  }

  function updatePassword(email, password, password_confirmation, token) {
    return authAxios.post(
      `${baseURL}/password/reset`,
      email,
      password,
      password_confirmation,
      token
    );
  }

  const value = {
    currentUser,
    userEmail,
    userName,
    AUTH_TOKEN,
    authAxios,
    login,
    signup,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
    deleteAccount,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
