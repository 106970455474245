import { Input, Tooltip } from "@mantine/core";
import React from "react";
import { AlertCircle } from "tabler-icons-react";
import CategoryChips from "./CategoryChips";
import { ThemedButton } from "./ThemedButton";

const rightSection = (
  <Tooltip
    label="Fewer keywords will be more accurate!"
    position="top"
    placement="end"
  >
    <AlertCircle size={22} style={{ display: "block", opacity: 0.5, marginRight: 8}} />
  </Tooltip>
);

const styles = {
  searchContainerSmall: {
    display: "flex",
    flexDirection: "row",
  },
  inputContainer: {
    marginLeft: 12,
    marginRight: 12,
    width: "82%",
  },
  buttonReverse: {
    display: "flex",
    flexDirection: "row-reverse",
  },
  chipContainer: {
    marginTop: 20,
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    paddingLeft: 50,
    paddingRight: 50,
  },
  chipContainerSmall: {
    marginTop: 12,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  smallContainer: { marginBottom: 16 },
  hidden: { display: "none" },
};

export function SearchComponent({
  matches,
  locationInput,
  setLocationInput,
  searchInput,
  setSearchInput,
  searchItems,
  chipVaule,
  setChipValue,
}) {
  const onKeyPressSubmit = (e) => {
    if (e.key === "Enter") {
      searchItems();
    }
  };

  return (
    <>
      <div style={matches ? styles.searchContainerSmall : null}>
        {/* <Input
          onKeyPress={onKeyPressSubmit}
          value={locationInput}
          onChange={(e) => setLocationInput(e.target.value)}
          radius={12}
          style={matches ? styles.inputContainer : styles.smallContainer}
          icon={"🗺️"}
          placeholder="Location Search"
          rightSection={rightSection}
          size="lg"
        /> */}

        <Input
          className="mx-auto"
          onKeyPress={onKeyPressSubmit}
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          radius={12}
          style={matches ? styles.inputContainer : styles.smallContainer}
          icon={"🔍"}
          rightSection={rightSection}
          placeholder="Job title, keywords or skills"
          size="lg"
        />

        <div style={!matches ? styles.hidden : null}>
          <ThemedButton onClick={searchItems}>Search</ThemedButton>
        </div>
      </div>
      <div style={matches ? styles.chipContainer : styles.chipContainerSmall}>
        <CategoryChips value={chipVaule} onChange={setChipValue} />
        <div style={matches ? styles.hidden : null}>
          <ThemedButton onClick={searchItems}>Search</ThemedButton>
        </div>
      </div>
    </>
  );
}
