import React from "react";
import { Switch, Route, useLocation } from "react-router-dom";

import Signup from "../../Screens/Signup";
import Login from "../../Screens/Login";
import LandingPage from "../../Screens/LandingPage";
import PrivateRoute from "../../components/RouteComponents/PrivateRoute";
import ForgotPassword from "../../Screens/ForgotPassword";
import UpdateProfile from "../../Screens/UpdateProfile";
import CreateJob from "../../Screens/CreateJob";
import UpdateJob from "../../Screens/UpdateJob";
import MyJobs from "../../Screens/MyJobs";
import SearchJob from "../../Screens/SearchJob";
import ViewJob from "../../Screens/ViewJob";
import AboutRoadmap from "../../Screens/AboutRoadmap";
import PrivacyPolicy from "../../Screens/PrivacyPolicy";
import TermsOfUse from "../../Screens/TermsOfUse";
import UpdatePasswordScreen from "../../Screens/UpdatePasswordScreen";
import NotFound from "../../Screens/NotFound";

import { AnimatePresence } from "framer-motion";
import UserNavBar from "../UserNavBar";

const AnimatedRoutes = () => {
  const location = useLocation();

  return (
    <>
      <UserNavBar />
      <AnimatePresence exitBeforeEnter initial={false}>
        <Switch location={location} key={location.pathname}>
          <PrivateRoute path="/update-profile" component={UpdateProfile} />
          <PrivateRoute path="/create-jobs" component={CreateJob} />
          <PrivateRoute path="/update-job/:uuid" component={UpdateJob} />
          <PrivateRoute path="/my-jobs" component={MyJobs} />
          <Route exact path="/" component={LandingPage} />
          <Route path="/roadmap" component={AboutRoadmap} />
          <Route path="/search-job:uuid" component={SearchJob} />
          <Route path="/jobs/:uuid" component={ViewJob} />
          <Route path="/signup" component={Signup} />
          <Route path="/login" component={Login} />
          <Route path="/privacypolicy" component={PrivacyPolicy} />
          <Route path="/termsofuse" component={TermsOfUse} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/update-password/api/password/find/:tokenID" component={UpdatePasswordScreen} />
          <Route component={NotFound} />          
        </Switch>
      </AnimatePresence>
    </>
  );
};

export default AnimatedRoutes;
