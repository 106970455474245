import React, { useState } from "react";
import "./footer.css";
import {
  Container,
  Space,
  Popover,
  Text,
  Title,
  useMantineColorScheme,
} from "@mantine/core";
import Donations from "./ThemedComponents/Donations";
import { Link } from "react-router-dom";
import Twitter from "../images/Twitter";

export default function Footer() {
  const [opened, setOpened] = useState(false);
  const { colorScheme } = useMantineColorScheme();
  const dark = colorScheme === "dark";

  const colorToggle = dark ? "#dee2e6" : "white";

  return (
    <footer style={{ paddingTop: 18 }}>
      <Container>
        <div className="footer-main">
          <div className="footer-column">
            <Text color={colorToggle} order={2} className="footer-heading">
              <Title order={2}>Donate</Title>
            </Text>
            <Donations noText />
          </div>
          <div className="footer-column">
            <Text color={colorToggle} order={2} className="footer-heading">
              <Title order={2}>About Us</Title>
            </Text>
            <Text color={colorToggle} className="footer-link">
              <Link to="/roadmap">About</Link>
            </Text>
            <Text
              color={colorToggle}
              component="a"
              href="/privacypolicy"
              className="footer-link"
            >
              Privacy Policy
            </Text>
            <Text
              color={colorToggle}
              component="a"
              href="/termsofuse"
              className="footer-link"
            >
              Terms of Use
            </Text>
          </div>
          <div className="footer-column">
            <Text color={colorToggle} order={2} className="footer-heading">
              <Title order={2}>Social</Title>
            </Text>
            <Popover
              shadow="xl"
              radius={12}
              position="bottom"
              trapFocus={false}
              closeOnEscape={false}
              transition="pop"
              transitionDuration={200}
              opened={opened}
              onClose={() => setOpened(false)}
              target={
                <Text
                  component="a"
                  style={{ cursor: "pointer" }}
                  onClick={() => setOpened((o) => !o)}
                  color={colorToggle}
                  className="footer-link"
                >
                  Twitter
                </Text>
              }
            >
              <Container>
              <Text
                  component="a"
                  href="https://twitter.com/jobsonblocks"
                  target="_blank"
                  style={{ cursor: "pointer" }}
                  size="md"
                  weight={500}
                >
                  <Twitter /> JobsOnblocks
                </Text>
              </Container>
            </Popover>

            <Text
              color={colorToggle}
              component="a"
              href={`mailto:jobsonblocks@protonmail.com?subject=Get in touch`}
              className="footer-link"
            >
              Contact
            </Text>
          </div>
        </div>
        <div className="footer-bottom">
          <Text color="grey" className="copyright">
            &copy; 2024 JobsOnBlocks
          </Text>
        </div>
      </Container>
    </footer>
  );
}
