import React from "react";
import { Center, Container, Text, Title } from "@mantine/core";
import { PageChangeContainer } from "../components/RouteComponents/PageChangeContainer";

const NotFound = () => {
  return (
    <Container px="xl">
    <PageChangeContainer
      style={{

      }}
    >
      <Center py="xl">
        <Title>
            <Text
            mt={300}
            align="center"
            style={{ fontSize: 30, fontWeight: 600 }}
            >
                🔎 Page Not Found 🤔
            </Text>
        </Title>
      </Center>
    </PageChangeContainer>
  </Container>
  );
};

export default NotFound;