import { ActionIcon, Menu } from "@mantine/core";
import React, { useState } from "react";
import { DotsVertical, Edit, Trash } from "tabler-icons-react";
import { FaShare } from "react-icons/fa";
import { iconTheme } from "../../Utils/theme";
import PopUp from "../RouteComponents/PopUp";
import { useHistory } from "react-router-dom";
import { showNotification } from "@mantine/notifications";
import { useAuth } from "../../contexts/AuthContext";
import { baseURL } from "../../Utils/Constants";
import ShareJobModal from "./ShareJobModal";

export function EditJobMenu({ job }) {
  const { authAxios } = useAuth();
  const [opened, setOpened] = useState(false);
  const [shareOpened, setShareOpened] = useState(false);
  const history = useHistory();

  const handleOnDelete = async () => {
    setOpened(false);
    try {
      await authAxios.delete(`${baseURL}/jobs/${job.uuid}`);
      showNotification({
        radius: 12,
        title: "Job deleted",
        message: "Your job was successfully deleted 👍",
        color: "green",
      });
      history.push(`/search-job:uuid`);
    } catch (e) {
      showNotification({
        radius: 12,
        title: "Job not deleted",
        message: `${e} Your job was not deleted please try again👎`,
        color: "red",
      });
    }
  };

  const handleOnEdit = () => {
    history.push(`/update-job/${job.uuid}`);
  };

  return (
    <>
      <PopUp opened={opened} setOpened={setOpened} onDelete={handleOnDelete} />
      <ShareJobModal
        opened={shareOpened}
        setOpened={setShareOpened}
        title={job.title}
        jobID={job.uuid}
      />
      <Menu
        control={
          <ActionIcon variant="hover">
            <DotsVertical size={48} strokeWidth={2} sx={iconTheme} />
          </ActionIcon>
        }
        radius={12}
        shadow="lg"
      >
        <Menu.Item
          onClick={() => setShareOpened(true)}
          icon={<FaShare size={20} />}
        >
          Share
        </Menu.Item>
        <Menu.Item onClick={handleOnEdit} icon={<Edit size={20} />}>
          Update
        </Menu.Item>
        <Menu.Item
          color="red"
          onClick={() => setOpened(true)}
          icon={<Trash size={20} />}
        >
          Delete
        </Menu.Item>
      </Menu>
    </>
  );
}
