import React from "react";
import { Center, Container, Text, Title } from "@mantine/core";
import { PageChangeContainer } from "../components/RouteComponents/PageChangeContainer";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import { ShieldLock } from "tabler-icons-react";

function PrivacyPolicy() {
  return (
    <>
      <Container px="xl">
        <PageChangeContainer
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "90vh",
          }}
        >
          <Title>
            <Text
              py="xl"
              align="center"
              style={{ fontSize: 28, fontWeight: 700 }}
            >
              🛡️ Privacy Policy
            </Text>
          </Title>
          <Center py="xl">
            <div style={{ maxWidth: 800 }}>
              <Text mb="md">
                <strong>🛡️ Your Privacy is Important</strong>
              </Text>
              <Text color="dimmed" size="sm">
              Welcome to JobsOnBlocks! Our app connects job seekers and employers, enabling transactions in cryptocurrency, 
              free of charge. Using our Service signifies your consent to the collection and use of your personal information 
              as detailed in this policy, linked closely with our Terms and Conditions.
              </Text>

              <Text mt="xl" mb="md">
                <strong>📝 Information Collection and Use</strong>
              </Text>
              <Text color="dimmed" size="sm">
              We collect personal information, such as email and username, to enhance your experience. This data is securely 
              stored and used solely to improve our service. Users wishing to remove their data from our platform can easily 
              do so by navigating to the account section and clicking the 'Delete Account' button. This action will 
              immediately and irrevocably destroy your personal information from our records.
              <br></br>
              <br></br>
              Our service allows you to share job posts on the following platforms: Facebook, LinkedIn, Reddit, Telegram, 
              WhatsApp, and Twitter. Please note that interactions with these platforms are governed by their respective privacy policies.
              </Text>

              <Text mt="xl" mb="md">
                <strong>🔒 Security</strong>
              </Text>
              <Text color="dimmed" size="sm">
              Your trust is vital to us. We're committed to protecting your personal information with commercially viable 
              methods. However, it's important to remember that no internet transmission or electronic storage is entirely 
              secure.
              </Text>

              <Text mt="xl" mb="md">
              <strong>🍪 Cookies</strong>
              </Text>
              <Text color="dimmed" size="sm">
              We use cookies, small data files stored on your device to manage functions like color schemes and menu status. 
              You have the option to accept or reject cookies, and we'll inform you when cookies are being sent to your device.
              </Text>
              
              <Text mt="xl" mb="md">
              <strong>📊 Log Data</strong>
              </Text>
              <Text color="dimmed" size="sm">
              In case of service errors, we collect log data that may include your device's IP address, browser configuration, 
              and usage statistics to help us diagnose and rectify the issues.
              </Text>

              <Text mt="xl" mb="md">
              <strong>🔄 Changes to This Privacy Policy</strong>
              </Text>
              <Text color="dimmed" size="sm">
              We periodically review and may update this privacy policy. We encourage you to check this page for any changes. 
              Updates will be posted here and take immediate effect.
              </Text>

              <Text mt="xl" mb="md">
                <strong>✉️ Contact Us</strong>
              </Text>
              <Text color="dimmed" size="sm">
              If you have concerns or questions about this Privacy Policy, please contact us at jobsonblocks@protonmail.com
              </Text>
            </div>
          </Center>
        </PageChangeContainer>
      </Container>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;