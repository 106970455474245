import React from "react";
import { showNotification } from "@mantine/notifications";
import { Button, Modal, Group, FloatingTooltip, Text } from "@mantine/core";
import {
  FacebookIcon,
  LinkedinIcon,
  RedditIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

const ShareJobModal = ({ opened, setOpened, title, jobID }) => {
  const url = "https://jobsonblocks.com/jobs/";

  const onCopy = (address) => {
    navigator.clipboard.writeText(address).then(
      showNotification({
        radius: 60,
        title: `Copied address`,
        message: "Share away!",
        styles: () => ({
          root: {
            backgroundColor: "#2bb37e",
            borderColor: "#2bb37e",
            "&::before": { backgroundColor: "#2bb37e" },
          },
          title: { color: "white" },
          description: { color: "white" },
          closeButton: {
            color: "white",
            "&:hover": { backgroundColor: "#3ba57d" },
          },
        }),
      })
    );
  };

  return (
    <Modal
      radius={12}
      opened={opened}
      onClose={() => setOpened(false)}
      title={<Text size="xl" weight={600}>Share this Job</Text>}
    >

      <Group spacing="xs">
        <TwitterShareButton
          hashtags={["JobsOnBlocks"]}
          title={title}
          url={`${url}${jobID}`}
        >
          <TwitterIcon size={34} round={true} />
        </TwitterShareButton>
        <FacebookShareButton title={title} url={`${url}${jobID}`}>
          <FacebookIcon size={34} round={true} />
        </FacebookShareButton>
        <RedditShareButton title={title} url={`${url}${jobID}`}>
          <RedditIcon size={34} round={true} />
        </RedditShareButton>
        <WhatsappShareButton title={title} url={`${url}${jobID}`}>
          <WhatsappIcon size={34} round={true} />
        </WhatsappShareButton>
        <LinkedinShareButton
          title={title}
          url={`${url}${jobID}`}
          source={`${url}${jobID}`}
        >
          <LinkedinIcon size={34} round={true} />
        </LinkedinShareButton>
        <TelegramShareButton title={title} url={`${url}${jobID}`}>
          <TelegramIcon size={34} round={true} />
        </TelegramShareButton>
        <FloatingTooltip label="Click me to copy!">
          <Button
            onClick={() => onCopy(`${url}${jobID}`)}
            radius={15}
            mt={0}
            pl={16}
            pr={16}
            ml={2}
            color="indigo"
            >
            Copy Job Link
          </Button>
        </FloatingTooltip>
      </Group>
    </Modal>
  );
};

export default ShareJobModal;
