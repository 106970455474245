import React from "react";
import { Center, Container, Text, Title } from "@mantine/core";
import { PageChangeContainer } from "../components/RouteComponents/PageChangeContainer";
import Footer from "../components/Footer";

function TermsOfUse() {
  return (
    <>
      <Container px="xl">
        <PageChangeContainer
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "85vh",
          }}
        >
          <Title>
            <Text
              py="xl"
              align="center"
              style={{ fontSize: 28, fontWeight: 700 }}
            >
              📜 Terms of Use
            </Text>
          </Title>
          <Center py="xl">
          <div style={{ maxWidth: 800 }}>
              <Text mb="md">
                <strong>🛠️ Your Use of Our Service</strong>
              </Text>
              <Text color="dimmed" size="sm">
              Welcome to JobsOnBlocks! By accessing or using our service, you agree to abide by these Terms of Use. Please 
              read them carefully before using our platform.
              </Text>

              <Text mt="xl" mb="md">
                <strong>📝 Use of Service</strong>
              </Text>
              <Text color="dimmed" size="sm">
              Our platform connects job seekers and employers for cryptocurrency-based transactions. You must use the 
              service only for its intended purpose and comply with all applicable laws and regulations.
              </Text>

              <Text mt="xl" mb="md">
                <strong>🔑 Account Registration</strong>
              </Text>
              <Text color="dimmed" size="sm">
              To access certain features, you may need to create an account. You agree to provide accurate and complete 
              information and to keep your account credentials secure.
              </Text>

              <Text mt="xl" mb="md">
                <strong>👤 User Conduct</strong>
              </Text>
              <Text color="dimmed" size="sm">
              You are accountable for your interactions on our platform. Please maintain respectfulness and refrain from 
              posting inappropriate content.
              </Text>
              
              <Text mt="xl" mb="md">
                <strong>🔗 Third-Party Links</strong>
              </Text>
              <Text color="dimmed" size="sm">
              Our platform may contain links to third-party websites or services. We are not responsible for the content 
              or privacy practices of these sites.
              </Text>

              <Text mt="xl" mb="md">
                <strong>💼 Limitation of Liability</strong>
              </Text>
              <Text color="dimmed" size="sm">
              In no event shall JobsOnBlocks be liable for any damages arising out of or in connection with your use of 
              our service. Our platform solely facilitates the connection between job seekers and employers. We are not 
              responsible for any actions or outcomes that occur after this connection is made.
              </Text>

              <Text mt="xl" mb="md">
                <strong>🔄 Changes to Terms</strong>
              </Text>
              <Text color="dimmed" size="sm">
              We reserve the right to update or modify these Terms of Use at any time. By continuing to use our service 
              after any changes, you agree to be bound by the revised terms.
              </Text>

              <Text mt="xl" mb="md">
                <strong>📧 Contact Us</strong>
              </Text>
              <Text color="dimmed" size="sm">
              If you have any questions or concerns about these Terms of Use, please contact us at jobsonblocks@protonmail.com
              </Text>
            </div>
          </Center>
        </PageChangeContainer>
      </Container>
      <Footer />
    </>
  );
}

export default TermsOfUse;