import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { Link } from "react-router-dom";
import { useMantineColorScheme } from "@mantine/core";
import {
  Paper,
  Button,
  Alert,
  Text,
  InputWrapper,
  Input,
  Divider,
  Center,
} from "@mantine/core";
import Footer from "../components/Footer";
import { PageChangeContainer } from "../components/RouteComponents/PageChangeContainer";
import { showNotification } from "@mantine/notifications";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { AlertCircle } from 'tabler-icons-react';
import HCaptcha from "@hcaptcha/react-hcaptcha";

export default function ForgotPassword() {
  const emailRef = useRef();
  const { resetPassword } = useAuth();
  const history = useHistory();
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const { colorScheme } = useMantineColorScheme();
  const captchaTheme = colorScheme === "dark" ? "dark" : "light"; // Dynamically set theme

  const [token, setToken] = useState(null);
  const captchaRef = useRef(null);

  useEffect(() => {
    if (token) console.log(`hCaptcha Token: ${token}`);
  }, [token]);

  async function handleResetPassword(e) {
    e.preventDefault();
    try {
      setMessage("");
      setError("");
      setLoading(true);
      await resetPassword(emailRef.current.value);
      showNotification({
        title: "Email sent 👍",
        message: "Check your inbox for further instructions",
        color: "green",
        radius: 12,
      });
      history.push("/login");
    } catch (e) {
      showNotification({
        title: "Email not sent 👎",
        message: `${
          e.response.data.error.email
            ? e.response.data.error.email
            : e.response.data.error
        }`,
        color: "red",
        radius: 12,
      });
    }
    setLoading(false);
  }

  return (
    <>
      <PageChangeContainer
        style={{
          width: 400,
          margin: "auto",
          marginTop: 40,
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Alert icon={<AlertCircle size={16} />} title="Notice!" color="red" radius="md" variant="outline">
          We're currently having issues with our mail provider. Resetting passwords is disabled until this is resolved. Sorry for the inconvenience.
        </Alert>
        <Divider my="sm" variant="dotted"/>
        <Paper radius={12} withBorder p="md" shadow="lg">
          <Text weight={500} size="xl" className="text-left">
            Reset Password
          </Text>
          <Text size="sm" className="mb-3">
            Enter your email below:
          </Text>
          {message && (
            <Alert title="Success" color="green">
              {message}
            </Alert>
          )}
          <Form>
            <InputWrapper
              className="mb-3"
              id="email-input"
              required
              label="Email"
              error={error}
            >
              <Input
                radius={8}
                ref={emailRef}
                id="email-input"
                placeholder="jobs@onblocks.com"
              />
            </InputWrapper>
            <Button
              onClick={handleResetPassword}
              radius={8}
              disabled={true}
              className="w-100"
              color="indigo"
              type="submit"
            >
              Reset Password
            </Button>
            <Center pt="xl">
              <HCaptcha
                sitekey={process.env.REACT_APP_CAPTCHA}
                onVerify={setToken}
                ref={captchaRef}
                theme={captchaTheme}
              />
            </Center>
          </Form>
          <div className="w-100 text-center mt-3">
            <Text className="text-center mt-2">
              <Link style={{ color: "#4c6ef5" }} to="/login">
                Login
              </Link>
            </Text>
          </div>
        </Paper>
        <div className="w-100 text-center mt-2">
          Need an account?{" "}
          <Link style={{ color: "#4c6ef5" }} to="/signup">
            Sign Up
          </Link>
        </div>
      </PageChangeContainer>
      <div style={{ flex: 1 }}>
        <Footer />
      </div>
    </>
  );
}
