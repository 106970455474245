import { Button, Container, Center, Text, Title, Tooltip } from "@mantine/core";
import SpinningBTC from "../images/SpinningBTC.gif";
import { useMediaQuery } from "@mantine/hooks";
import React from "react";
import { useHistory } from "react-router-dom";

const HeaderSection = () => {
  const matches = useMediaQuery("(max-width: 1000px)");
  const history = useHistory();

  const pushToSignup = () => {
    history.push("/search-job:uuid");
  };

  return (
    <div
      style={
        matches
          ? { marginTop: 30 }
          : { marginTop: 50, marginBottom: 10, height: 170 }
      }
    >
      <Container size="md" px="xl">
        <Title align="center" style={{ fontSize: 60, fontWeight: 700 }}>
          Hire for crypto,
          <Text color="#4c6ef5" inherit component="span">
            {" "}
            earn{" "}
          </Text>
          crypto{" "}
          <Tooltip label="The OG!">
            <img style={{ height: 70, width: 70 }} src={SpinningBTC} />
          </Tooltip>
        </Title>
      </Container>
      <Center style={{ marginTop: 40 }}>
        <Button
          variant="default"
          color="dark"
          radius={8}
          onClick={pushToSignup}
          size="xl"
        >
          See Jobs 🚀
        </Button>
      </Center>
      <Center pt="xl"></Center>
    </div>
  );
};

export default HeaderSection;
