import { Pagination } from "@mantine/core";
import { useWindowScroll } from "@mantine/hooks";
import React, { useEffect } from "react";

export default function JobPagination({
  total,
  currentPage,
  setCurrentPage,
  postsPerPage,
}) {
  const [scroll, scrollTo] = useWindowScroll();

  useEffect(() => {
    const scrollToTop = () => {
      scrollTo({ y: 0 });
    };
    scrollToTop();
  }, [currentPage]);

  return (
    <>
      {postsPerPage === 0 || postsPerPage == null ? null : (
        <Pagination
          className="mt-3 mb-4"
          page={currentPage}
          onChange={setCurrentPage}
          position="center"
          total={total}
          color="indigo"
          radius={12}
          withEdges
          size="lg"
        />
      )}
    </>
  );
}
