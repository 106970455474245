import React, { useEffect } from "react";
import HeaderSection from "../components/HeaderSection";
import About from "../components/ThemedComponents/About";
import { motion } from "framer-motion";
import { useAuth } from "../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import Footer from "../components/Footer";

const LandingPage = () => {
  const { currentUser, logout } = useAuth();
  const history = useHistory();

  async function handleLogout() {
    if (currentUser) {
      await logout();
      history.push("/");
    }
  }
  handleLogout();

  return (
    <motion.div
      initial={{ height: 0 }}
      animate={{ height: "100%" }}
      exit={{ y: window.innerHeight, transition: { duration: 0.4 } }}
    >
      <HeaderSection />
      <About />
      <div style={{ backgroundColor: "#141517", height: "43vh" }}>
        <Footer afterContent />
      </div>
    </motion.div>
  );
};

export default LandingPage;
