import { Text, Title, useMantineColorScheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

import React from "react";
import Wave from "../../images/Wave";

function About() {
  const matches = useMediaQuery("(max-width: 1100px)");
  const { colorScheme } = useMantineColorScheme();
  const dark = colorScheme === "dark";

  return (
    <>
      <Wave />
      <div
        style={{
          marginTop: -1,
          backgroundColor: "#141517",
          padding: 20,
          maxWidth: "100%",
          height: "100%",
        }}
      >
        <div style={matches ? { marginTop: -20 } : { marginTop: -80 }}>
          <Title align="center">
            <Text
              align="center"
              style={{ fontSize: 30, fontWeight: 700, paddingBottom: 20 }}
              color={dark ? "#dee2e6" : "white"}
            >
              We are on a quest!
            </Text>
          </Title>
          <div
            className="mx-auto"
            style={{
              maxWidth: 900,
            }}
          >
            <Text
              style={{ lineHeight: 1.87 }}
              align="center"
              color={dark ? "#dee2e6" : "white"}
              size="xl"
            >
              We aim to simplify earning cryptocurrency by offering a comprehensive
              ecosystem for job seekers and employers to connect and transact in
              cryptocurrency.
            </Text>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
