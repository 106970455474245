import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import {
  Paper,
  Button,
  Text,
  InputWrapper,
  PasswordInput,
} from "@mantine/core";
import Footer from "../components/Footer";
import { PageChangeContainer } from "../components/RouteComponents/PageChangeContainer";
import { showNotification } from "@mantine/notifications";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import { baseURL } from "../Utils/Constants";

export default function UpdatePasswordScreen() {
  const { tokenID } = useParams();
  const history = useHistory();
  const passwordRef = useRef("");
  const confirmPasswordRef = useRef("");
  const [data, setData] = useState("");
  const { updatePassword } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchID = async (url) => {
      setError(false);
      setLoading(true);
      try {
        const response = await axios(url);
        setData(response?.data);
      } catch (e) {
        setError(true);
      }
      setLoading(false);
    };
    fetchID(`${baseURL}/password/find/${tokenID}`);
  }, [tokenID]);

  async function handlePasswordUpdate(e) {
    e.preventDefault();
    try {
      setError("");
      setLoading(true);
      await updatePassword({
        email: data.email,
        password: passwordRef.current.value,
        password_confirmation: confirmPasswordRef.current.value,
        token: tokenID,
      });
      showNotification({
        title: "Password updated 👍",
        message: "You will now be able to log in",
        color: "green",
        radius: 12,
      });
      history.push("/login");
    } catch (e) {
      showNotification({
        title: "Password not updated 👎",
        message: `${e.message}`,
        color: "red",
        radius: 12,
      });
    }
    setLoading(false);
  }

  return (
    <>
      <PageChangeContainer
        style={{
          width: 400,
          margin: "auto",
          marginTop: 20,
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Paper radius={12} withBorder p="md" shadow="lg">
          <Text weight={500} size="xl" className="text-left">
            Change your password
          </Text>
          <Text size="sm" className="mb-3">
            Enter your new password below
          </Text>
          <Form onSubmit={handlePasswordUpdate}>
            <InputWrapper
              description="Your new password must be over 8 characters long"
              className="mb-3"
              id="password-inputs"
              required
              label="New passwords"
              error={error}
            >
              <PasswordInput
                className="mb-3"
                radius={8}
                ref={passwordRef}
                id="password-input"
                placeholder="New password"
              />
              <PasswordInput
                radius={8}
                ref={confirmPasswordRef}
                id="confirm-password-input"
                placeholder="Confirm New password"
              />
            </InputWrapper>
            <Button
              radius={8}
              disabled={loading}
              className="w-100"
              color="indigo"
              type="submit"
            >
              Update Password
            </Button>
          </Form>
        </Paper>
      </PageChangeContainer>
      <div style={{ flex: 1 }}>
        <Footer />
      </div>
    </>
  );
}
