import React, { useState } from "react";
import { Button, Container, Text } from "@mantine/core";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { PageChangeContainer } from "../RouteComponents/PageChangeContainer";
import { showNotification } from "@mantine/notifications";

export function AccountUpdate({}) {
  const { resetPassword, userEmail } = useAuth();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  async function handleResetPassword() {
    setLoading(true);
    try {
      await resetPassword(userEmail);
      showNotification({
        title: "Email sent👍",
        message: "Check your inbox for further instructions",
        color: "green",
        radius: 12,
      });
      history.push("/search-job:uuid");
    } catch (e) {
      showNotification({
        title: "Email not sent 👎",
        message: `${
          e.response.data.error.email
            ? e.response.data.error.email
            : e.response.data.error
        }`,
        color: "red",
        radius: 12,
      });
    }
    setLoading(false);
  }

  return (
    <Container>
      <PageChangeContainer>
        <Text pb="sm" size="xl" weight={600}>
          Reset your password
        </Text>
        <Text weight={500} pb="sm">
          An email will be set to: {userEmail}
        </Text>
        <Button
          onClick={handleResetPassword}
          radius={8}
          disabled={loading}
          className="w-100"
          color="indigo"
          type="submit"
        >
          Reset Password
        </Button>
      </PageChangeContainer>
    </Container>
  );
}
