import React, { useEffect, useState } from "react";
import {
  Chip,
  Chips,
  Button,
  Collapse,
  Text,
  Container,
  useMantineColorScheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { ChevronDown, ChevronUp } from "tabler-icons-react";

const CategoryChips = ({ value, onChange }) => {
  const matches = useMediaQuery("(max-width: 768px)");
  const [open, setOpen] = useState(true);

  const { colorScheme } = useMantineColorScheme();
  const dark = colorScheme === "dark";

  //setting cookies to determine whether search menu is open or closed on mobile
  useEffect(() => {
    const quickSearchOpen = localStorage.getItem("quickSearchOpen");
    if (quickSearchOpen === "true") {
      setOpen(true);
    } else if (quickSearchOpen === "false") {
      setOpen(false);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("quickSearchOpen", open);
  }, [open]);

  const searchChips = (
    <Chips
      size="md"
      radius={12}
      color="indigo"
      multiple={false}
      value={value}
      onChange={onChange}
    >
      <Chip value="all">All</Chip>
      <Chip value="design">Design</Chip>
      <Chip value="web3">Web3</Chip>
      <Chip value="tutor">Tutor</Chip>
      <Chip value="programmer">Programmer</Chip>
      <Chip value="marketing">Marketing</Chip>
      <Chip value="art">Art</Chip>
      <Chip value="trade">Trade</Chip>
      <Chip value="manuallabour">Manual Labour</Chip>
      <Chip value="hospitality">Hospitality</Chip>
      <Chip value="support Work">Support Work</Chip>
      <Chip value="tourism">Tourism</Chip>
      <Chip value="writing">Writing</Chip>
      <Chip value="other">Other</Chip>
    </Chips>
  );

  return (
    <>
      {matches ? (
        <div>
          <Button
            variant="default"
            color="dark"
            radius={12}
            size="lg"
            onClick={() => setOpen(!open)}
          >
            <Text color={dark ? "#dee2e6" : "dark"}>
              Quick Search {open ? <ChevronUp /> : <ChevronDown />}
            </Text>
          </Button>
          <Collapse transitionTimingFunction="linear" in={open}>
            {open && <div style={{ marginTop: 12 }}>{searchChips}</div>}
          </Collapse>
        </div>
      ) : (
        <Container align="center">
          <Text
            pb="sm"
            color={dark ? "#dee2e6" : "dark"}
            align="left"
            size="md"
            weight={500}
          >
            Quick Search Options:
          </Text>
          {searchChips}
        </Container>
      )}
    </>
  );
};

export default CategoryChips;
