import { Text } from "@mantine/core";
import React from "react";

const styles = {
  spacing: {
    marginTop: 8,
    marginBottom: -10,
  },
};

export function SearchResults({ loading, currentPage, data }) {
  return (
    <>
      {loading && (
        <Text size="sm" style={styles.spacing} color="grey" align="center">
          ⌛
        </Text>
      )}
      {!loading && data.length !== 0 && (
        <Text size="sm" style={styles.spacing} color="grey" align="center">
          Page {currentPage} of {data.length} jobs
        </Text>
      )}
      {!loading && data.length === 0 && (
        <Text size="sm" style={styles.spacing} color="grey" align="center">
          No results found 😞
        </Text>
      )}
    </>
  );
}
