import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { Link, useHistory } from "react-router-dom";
import {
  Paper,
  Button,
  Input,
  InputWrapper,
  PasswordInput,
  Text,
} from "@mantine/core";
import Footer from "../components/Footer";
import { PageChangeContainer } from "../components/RouteComponents/PageChangeContainer";

const Login = () => {
  const { login } = useAuth();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(false);
    setEmailError("");
    setPasswordError("");
    if (email === "") {
      setEmailError("Email Is required");
      return;
    }
    if (password === "") {
      setPasswordError("Please enter your password");
      return;
    }
    try {
      setLoading(true);
      await login(email, password);
      history.push("/search-job:uuid");
    } catch (e) {
      setEmailError(e.response.data.message);
      setLoading(false);
    }
    setLoading(false);
  };
  return (
    <>
      <PageChangeContainer
        style={{
          width: 400,
          margin: "auto",
          marginTop: 40,
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Paper radius={12} withBorder p="md" shadow="lg">
          <Text weight={500} size="xl" className="mb-3 text-center">
            Login to your Account
          </Text>
          <Form onSubmit={handleSubmit}>
            <InputWrapper
              className="mb-3"
              id="email-input"
              required
              label="Email"
              error={emailError}
            >
              <Input
                invalid={emailError}
                radius={8}
                onChange={(e) => setEmail(e.target.value)}
                id="email-input"
                placeholder="jobs@onblocks.com"
              />
            </InputWrapper>
            <InputWrapper
              className="mb-4"
              id="password-input"
              required
              label="Password"
            >
              <PasswordInput
                error={passwordError}
                radius={8}
                onChange={(e) => setPassword(e.target.value)}
                id="password-input"
                placeholder="password"
              />
            </InputWrapper>
            <Button
              radius={8}
              color="indigo"
              disabled={loading}
              className="w-100"
              type="submit"
            >
              Log In
            </Button>
          </Form>
          <div className="w-100 text-center mt-3">
            <Text className="text-center mt-2">
              <Link style={{ color: "#4c6ef5" }} to="/forgot-password">
                Forgot Password?
              </Link>
            </Text>
          </div>
        </Paper>
        <Text className="text-center mt-3">
          Need an account?{" "}
          <Link style={{ color: "#4c6ef5" }} to="/signup">
            Sign Up
          </Link>
        </Text>
      </PageChangeContainer>
      <div style={{ flex: 1 }}>
        <Footer />
      </div>
    </>
  );
};
export default Login;
