import {
  Container,
  Divider,
  Space,
  Text,
  Group,
  Button,
} from "@mantine/core";
import React from "react";
import Moment from "react-moment";
import JobEmploymentTypeBadge from "../JobComponents/JobEmploymentTypeBadge";
import JobHireTypeBadge from "../JobComponents/JobHireTypeBadge";
import ShareSocials from "../JobComponents/ShareSocial";
import CoinJobIcons from "../ThemedComponents/CoinJobIcons";
import { RichTextEditor } from '@mantine/rte';

const DrawerJobDisplay = ({ job }) => {
  const jobCategory = [job.category];

  return (
      <Container p="xs" style={{ overflowY: 'scroll', height: '100%', OverflowStyle: 'none', scrollbarWidth: 'none'}}>
        <Group position="apart">
          <Text size="xl" weight={600}>
            Job Details:
          </Text>
          <ShareSocials title={job.title} jobID={job.uuid} size={26} shareMyJob />
        </Group>
        <Space h="xs" />
        <Group pb={3} spacing="xs">
          <Text color="dimmed" weight={500}>
            User:
          </Text>
          <Text weight={400}>{job.user.name}</Text>
        </Group>
        <Group pb={3} spacing="xs">
          <Text color="dimmed" weight={500}>
            Category:
          </Text>
          <Text weight={400}>{jobCategory}</Text>
        </Group>
        <Group pb={3} spacing="xs">
          <Text color="dimmed" weight={500}>
            Location:
          </Text>
          <Text weight={400}>{!job.location ? "Remote 🌎" : job.location}</Text>
        </Group>
        <Group pb={3} spacing="xs">
          <Text color="dimmed" weight={500}>
            Date posted:
          </Text>
          <Text weight={400}>
            <Moment format="YYYY/MM/DD">{job.created_at}</Moment>
          </Text>
        </Group>
        <Group pb={3} spacing="xs">
          <Text color="dimmed" weight={500}>
            Payment Coin:
          </Text>
          <Text weight={400}>{job.currency_type}</Text>
          <CoinJobIcons job={job} />
        </Group>
        <Space h="xs" />
        <Group position="apart">
          <Group spacing="xs">
            <JobHireTypeBadge pt={3} largeSize job={job} />
            <JobEmploymentTypeBadge pt={3} largeSize job={job} />
          </Group>
          <Button
            size="sm"
            weight={500}
            radius={8}
            mt={-2}
            color="indigo"
            style={{ color: "white", textDecoration: "none" }}
            component="a"
            href={`mailto:${job.user.email}?subject=${job.title} job ad`}
          >
            Apply via email
          </Button>
        </Group>
        <Divider my="sm" style={{ borderColor: '#2f2f2f' }} />
        <Space h="xs" />
        <Text size="xl" weight={600}>
          Job Description:
        </Text>
        <Space h="xs" />
        <RichTextEditor 
          controls={[
            ['bold', 'italic', 'underline'],
            ['unorderedList', 'orderedList'],
            ['link'],
          ]}
        value={job.description} readOnly={true} />
        
        <Space h="lg" />
        <Divider my="sm" style={{ borderColor: '#2f2f2f' }} />
        </Container>

  );
};

export default DrawerJobDisplay;