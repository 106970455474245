import { Container, Input, ScrollArea, Text } from "@mantine/core";
import React, { useEffect, useState } from "react";
import Job from "../components/JobComponents/Job";
import { baseURL } from "../Utils/Constants";
import JobSkeleton from "../components/JobComponents/JobSkeleton";
import axios from "axios";

const MyJobs = () => {
  const [searchInput, setSearchInput] = useState("");
  const [job, setJob] = useState("");

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const axiosOnRender = async (url) => {
      setSearchInput("");
      setError("");
      setLoading(true);
      try {
        const response = await axios.get(url);
        setJob(response?.data);
        if (job.Error) {
          setError(`${job.Error}`);
          return;
        }
      } catch (e) {
        if (axios.isCancel(e)) {
          return;
        }
        setError(e);
      }
      setLoading(false);
    };
    // fetch current user jobs with new endpoint
    axiosOnRender(`${baseURL}/user/jobs/myjobs/`);
  }, []);

  return (
    <Container py="xl">
      <Input
        className="mx-auto"
        style={{ maxWidth: 600 }}
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        radius={12}
        icon={"🔍"}
        placeholder="Filter through your jobs"
        size="lg"
      />
      <ScrollArea style={{ height: "100vh" }}>
        {loading && !error && (
          <>
            <JobSkeleton />
            <JobSkeleton />
            <JobSkeleton />
          </>
        )}
        {!loading && (
          <>
            {job.length === 0 && (
              <Text align="center" size="xl" py="xl">
                You have no jobs 😔
              </Text>
            )}
            {!loading &&
              job
                .filter((job) => {
                  if (searchInput === "") {
                    return job;
                  } else if (job.title.toLowerCase().includes(searchInput)) {
                    return job;
                  }
                })
                .map((job) => {
                  return (
                    <div
                      key={job.uuid}
                      className="mx-auto"
                      style={{ maxWidth: 600 }}
                    >
                      <Job editJob job={job} />
                    </div>
                  );
                })}
          </>
        )}
      </ScrollArea>
    </Container>
  );
};

export default MyJobs;
