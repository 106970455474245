import React from "react";
import { Center, Group, Text, FloatingTooltip } from "@mantine/core";
import { showNotification } from "@mantine/notifications";

import BTC from "../../images/BTC";
import DCR from "../../images/DCR";
import ETH from "../../images/ETH";
import XMR from "../../images/XMR";
import LTC from "../../images/LTC";

const Donations = ({ bottom, noText }) => {
  const onCopy = (address, coin) => {
    navigator.clipboard.writeText(address);

    showNotification({
      radius: 60,
      title: `Copied ${coin} address`,
      message: "Thanks for clicking!",
      styles: () => ({
        root: {
          backgroundColor: "#2bb37e",
          borderColor: "#2bb37e",
          "&::before": { backgroundColor: "#2bb37e" },
        },
        title: { color: "white" },
        description: { color: "white" },
        closeButton: {
          color: "white",
          "&:hover": { backgroundColor: "#3ba57d" },
        },
      }),
    });
  };
  return (
    <div
      style={bottom && { position: "absolute", left: 0, bottom: 10, right: 0 }}
    >
      <Center py="sm">
        <Group spacing="sm">
          {!noText && (
            <Text weight={450} size="sm">
              Enjoying the project? Buy us a coffee!
            </Text>
          )}
          <FloatingTooltip
            onClick={() =>
              onCopy("bc1qp5uchefgmvd2x3wdjllypcalj70m2w9snvtd2z", "Bitcoin", <BTC />)
            }
            label="Copy Bitcoin address"
          >
            <BTC />
          </FloatingTooltip>
          <FloatingTooltip
            onClick={() =>
              onCopy("DsYjxtZmLC9GSTbGf5Afx9nC2HGwgATJAfX", "Decred", <DCR />)
            }
            label="Copy Decred address"
          >
            <DCR />
          </FloatingTooltip>
          <FloatingTooltip
            onClick={() =>
              onCopy(
                "428DiqTpEXGbRf2Y41jW9tKwc3NYb3A5x36uAftv4SytDNAZa2XKyPpZfwGH4fZKsTKWDyF5PtHDDRCjmC7ec4VMJ8E9anv",
                "Monero",
                <XMR />
              )
            }
            label="Copy Monero address"
          >
            <XMR />
          </FloatingTooltip>
          <FloatingTooltip
            onClick={() =>
              onCopy(
                "0xaC539be38B31F5737605652d84b712676575B713",
                "Ethereum",
                <ETH />
              )
            }
            label="Copy Ethereum address"
          >
            <ETH />
          </FloatingTooltip>
          <FloatingTooltip
            onClick={() =>
              onCopy("ltc1q36sk386rr6w69lgufvng7gkxdp4jyrpls67zen", "Litecoin", <LTC />)
            }
            label="Copy Litecoin address"
          >
            <LTC />
          </FloatingTooltip>
        </Group>
      </Center>
    </div>
  );
};

export default Donations;
