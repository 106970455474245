import React, { useState } from "react";
import { Button, Container, Text } from "@mantine/core";
import { useAuth } from "../../contexts/AuthContext";
import { PageChangeContainer } from "../RouteComponents/PageChangeContainer";
import PopUp from "../RouteComponents/PopUp";
import { useHistory } from "react-router-dom";

export function UserInfo({}) {
  const { userEmail, userName, deleteAccount } = useAuth();
  const [open, setOpen] = useState();
  const history = useHistory();

  const handleDeleteAccount = () => {
    deleteAccount();
    history.push("/");
  };

  return (
    <>
      <PopUp
        opened={open}
        setOpened={setOpen}
        onDelete={handleDeleteAccount}
        title="Permanently delete your account?"
      />
      <Container>
        <PageChangeContainer>
          <Text pb="sm" size="xl" weight={600}>
            User information
          </Text>
          <Text weight={500}>Username: {userName}</Text>
          <Text pb="lg" weight={500}>
            Current email: {userEmail}
          </Text>
          <Button
            onClick={() => setOpen(!open)}
            radius={4}
            color="red"
            className="w-100"
          >
            Delete Account
          </Button>
        </PageChangeContainer>
      </Container>
    </>
  );
}
