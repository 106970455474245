import { Center, Tooltip } from "@mantine/core";
import React from "react";
import Verify from "../../images/verify.png";

function VerifyedJobLogo({ job }) {
  return (
    <>
      {job.user.is_verified === 1 && (
        <Tooltip
          position="bottom"
          wrapLines
          withArrow
          transition="pop"
          transitionDuration={200}
          label="This job belongs to a verified account."
        >
          <Center>
            <img
              style={{
                height: 22,
                width: 22,
              }}
              alt="verified logo"
              src={Verify}
            />
          </Center>
        </Tooltip>
      )}
    </>
  );
}
export default VerifyedJobLogo;
