export const jobData = [
  "Web3",
  "Design",
  "Programmer",
  "Tutor",
  "Art",
  "Trade",
  "Manual Labour",
  "Hospitality",
  "Support Work",
  "Tourism",
  "Writing",
  "Other",
];

export const baseURL = "https://jobsonblocks.com/api";

//export const baseURL = "http://127.0.0.1:8000";

export const coinData = [
  {
    label: "Bitcoin (BTC)",
    value: "Bitcoin",
  },
  {
    label: "Monero (XMR)",
    value: "Monero",
  },
  {
    label: "Decred (DCR)",
    value: "Decred",
  },
  {
    label: "Ethereum (ETH)",
    value: "Ethereum",
  },
  {
    label: "Litecoin (LTC)",
    value: "Litecoin",
  },
  {
    label: "Binance Smart Chain (BNB)",
    value: "Binance Smart Chain",
  },
  {
    label: "Ripple (XRP)",
    value: "Ripple",
  },
  {
    label: "Cardano (ADA)",
    value: "Cardano",
  },
  {
    label: "Firo (FIRO)",
    value: "Firo",
  },
  {
    label: "Solana (SOL)",
    value: "Solana",
  },
  {
    label: "Doge (DOGE)",
    value: "Doge",
  },
  {
    label: "Negotiable",
    value: "Negotiable",
  },
];

export const initialValue = "";
