import { Badge } from "@mantine/core";
import React from "react";

const JobHireTypeBadge = ({ job, largeSize }) => {
  return (
    <div style={{ paddingBottom: 8, paddingTop: 4 }}>
      {job?.job_hire_type === "Hiring" && (
        <Badge size={largeSize && "lg"} color="pink">
          Hiring
        </Badge>
      )}
      {job?.job_hire_type === "ForHire" && (
        <Badge size={largeSize && "lg"} color="orange">
          For Hire
        </Badge>
      )}
    </div>
  );
};
export default JobHireTypeBadge;
