import React, { useEffect, useState } from "react";
import { AuthProvider } from "./contexts/AuthContext";
import { BrowserRouter as Router } from "react-router-dom";
import { useWindowScroll } from "@mantine/hooks";
import {
  ActionIcon,
  Affix,
  Transition,
  MantineProvider,
  ColorSchemeProvider,
} from "@mantine/core";
import { ArrowUp } from "tabler-icons-react";
import AnimatedRoutes from "./components/RouteComponents/AnimatedRoutes";
import { NotificationsProvider } from "@mantine/notifications";
import { getCookie, setCookies } from "cookies-next";

function App() {
  const [scroll, scrollTo] = useWindowScroll();

  // dark mode and light mode logic
  const [colorScheme, setColorScheme] = useState("dark");
  const nextColorScheme = colorScheme === "dark" ? "light" : "dark";
  const toggleColorScheme = () => {
    setColorScheme(colorScheme === "dark" ? "light" : "dark");
    setColorScheme(nextColorScheme);
    setCookies("mantine-color-scheme", nextColorScheme, {
      maxAge: 60 * 60 * 24 * 30,
      sameSite: true,
    });
  };

  useEffect(() => {
    if (getCookie("mantine-color-scheme") === "light") {
      setColorScheme("light");
    } else if (getCookie("mantine-color-scheme") === "dark") {
      setColorScheme("dark");
    }
  }, []);

  return (
    <ColorSchemeProvider
      colorScheme={colorScheme}
      toggleColorScheme={toggleColorScheme}
    >
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        theme={{ fontFamily: "Poppins", colorScheme }}
      >
        <NotificationsProvider transitionDuration={500} autoClose={3000}>
          <AuthProvider>
            <Router>
              <AnimatedRoutes />
            </Router>
          </AuthProvider>
        </NotificationsProvider>
        <Affix position={{ bottom: 27, right: 20 }}>
          <Transition transition="slide-up" mounted={scroll.y > 0}>
            {(transitionStyles) => (
              <ActionIcon
                radius="xl"
                size={50}
                variant="filled"
                color="indigo"
                style={transitionStyles}
                onClick={() => scrollTo({ y: 0 })}
              >
                <ArrowUp />
              </ActionIcon>
            )}
          </Transition>
        </Affix>
      </MantineProvider>
    </ColorSchemeProvider>
  );
}

export default App;
