import { Button, Container, Group, Modal } from "@mantine/core";
import React from "react";

function PopUp({ onDelete, opened, setOpened, title }) {
  return (
    <Modal
      opened={opened}
      onClose={() => setOpened(false)}
      title={title ? title : "Are you sure you want to delete this job?"}
      radius={12}
    >
      <Container>
        <Group position="center">
          <Button onClick={onDelete} radius={12} color="indigo">
            Confirm
          </Button>
          <Button
            onClick={() => setOpened(false)}
            radius={12}
            variant="outline"
            color="dark"
          >
            Go Back
          </Button>
        </Group>
      </Container>
    </Modal>
  );
}
export default PopUp;
