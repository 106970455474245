import {
  Button,
  Chip,
  Chips,
  InputWrapper,
  LoadingOverlay,
  Select,
  Divider,
  Input,
  Paper,
  Loader,
  Container,
} from "@mantine/core";
import { useScrollLock } from "@mantine/hooks";
import { useHistory } from "react-router-dom";
import React, { useState } from "react";
import { coinData, jobData } from "../Utils/Constants";
import { useAuth } from "../contexts/AuthContext";
import { baseURL } from "../Utils/Constants";
import { PageChangeContainer } from "../components/RouteComponents/PageChangeContainer";
import { showNotification } from "@mantine/notifications";
import { RichTextEditor } from '@mantine/rte';
import DOMPurify from 'dompurify';

const styles = {
  spacing: {
    marginBottom: 12,
  },
};

const CreateJob = () => {
  const history = useHistory();
  const { userEmail, authAxios } = useAuth();
  const [scrollLocked, setScrollLocked] = useScrollLock(false);

  const [job, setJob] = useState("");
  const [jobError, setJobError] = useState("");

  const [location, setLocation] = useState("");

  const [category, setCategory] = useState("");
  const [categoryError, setCategoryError] = useState("");

  const [currency, setCurrency] = useState("Negotiable");

  const [value, setValue] = useState("");
  const [error, setError] = useState("");

  const [employTypechipValue, setEmployTypeChipValue] = useState("Onetime");
  const [hireTypechipValue, setHireTypeChipValue] = useState("Hiring");
  const [visible, setVisible] = useState(false);

  const sanitizedDescription = DOMPurify.sanitize(value);

  const handleSubmit = async () => {
    setJobError("");
    setCategoryError("");
    setError("");
    if (job === "") {
      setJobError("Please enter a job title");
      return;
    }
    if (category === "") {
      setCategoryError("Please select a category");
      return;
    }
    if (value === "") {
      setError("Please enter your job description ");
      return;
    }
    if (!currency) {
      setCurrency("Negotiable");
    }
    setScrollLocked(true);
    setVisible(true);
    try {
      await authAxios.post(`${baseURL}/jobs`, {
        title: job,
        currency_type: currency,
        location: location,
        job_employment_type: employTypechipValue,
        job_hire_type: hireTypechipValue,
        category: category,
        description: sanitizedDescription,
      });
      showNotification({
        title: "Job created 👍",
        message: "Your job was successfully submitted!",
        color: "green",
        radius: 12,
      });
      history.push("/my-jobs");
    } catch (e) {
      showNotification({
        title: "Job creation error 👎",
        message: "Your job was not submitted, please try again ",
        color: "red",
        radius: 12,
      });
    }
    setScrollLocked(false);
    setVisible(false);
  };

  return (
    <>
      <LoadingOverlay
        loader={<Loader color="indigo" size="lg" variant="dots" />}
        visible={visible}
      />
      <PageChangeContainer>
        <Container py="xl">
          <Paper radius={12} withBorder p="md" shadow="xl">
            <InputWrapper error={jobError} size="md" label="Job Title" required>
              <Input
                radius={8}
                style={styles.spacing}
                onChange={(e) => setJob(e.target.value)}
                placeholder="Job Title"
                size="md"
                required
                onPaste={(e) => {
                  e.preventDefault();
                  const text = e.clipboardData.getData('text/plain');
                  setJob(text);
                }}
              />
            </InputWrapper>
            <InputWrapper
              label="Location"
              size="md"
            >
              <Input
                style={styles.spacing}
                radius={8}
                onChange={(e) => setLocation(e.target.value)}
                placeholder="Optional"
                label="Location"
                size="md"
                onPaste={(e) => {
                  e.preventDefault();
                  const text = e.clipboardData.getData('text/plain');
                  setLocation(text);
                }}
              />
            </InputWrapper>
            <InputWrapper
              error={categoryError}
              description="Select 'Other' if you do not see a related category."
              label="Job Category"
              size="md"
              required
            >
              <Select
                radius={8}
                style={styles.spacing}
                placeholder="Search category list"
                data={jobData}
                searchable
                size="md"
                onChange={setCategory}
                transitionDuration={150}
                transition="pop-top-left"
                transitionTimingFunction="ease"
              />
            </InputWrapper>
            <InputWrapper error={error} size="md" label="Job Description" required>
                  <RichTextEditor
                    value={value}
                    onChange={setValue}
                    style={{ width: '100%', overflow: 'hidden', overflowWrap: 'break-word', wordBreak: 'break-word'}}
                    controls={[
                      ['bold', 'italic', 'underline'],
                      ['unorderedList', 'orderedList'],
                      ['link'],
                    ]}
                  />
              </InputWrapper>
            <InputWrapper
              style={(styles.spacing, { marginTop: 14 })}
              label="Preferred Method of Payment"
              size="md"
            >
              <Select
                radius={8}
                data={coinData}
                onChange={setCurrency}
                searchable
                size="md"
                transitionDuration={150}
                transition="pop-top-left"
                transitionTimingFunction="ease"
                placeholder="Leaving empty will default to Any"
              />
            </InputWrapper>
            <InputWrapper
              style={(styles.spacing, { marginTop: 14 })}
              label="Employment Type"
              size="md"
            >
              <Chips
                color="indigo"
                variant="filled"
                size="md"
                style={{ marginLeft: -3, marginTop: 4 }}
                radius={12}
                value={employTypechipValue}
                onChange={setEmployTypeChipValue}
              >
                <Chip value="Fulltime">Full-time</Chip>
                <Chip value="Parttime">Part-Time</Chip>
                <Chip value="Onetime">One-Time</Chip>
              </Chips>
            </InputWrapper>

            <InputWrapper
              style={(styles.spacing, { marginTop: 14 })}
              label="Job Hire Type"
              size="md"
            >
              <Chips
                color="indigo"
                variant="filled"
                size="md"
                radius={12}
                style={{ marginLeft: -3, marginTop: 4}}
                value={hireTypechipValue}
                onChange={setHireTypeChipValue}
              >
                <Chip value="Hiring">Hiring</Chip>
                <Chip value="ForHire">For Hire</Chip>
              </Chips>


            </InputWrapper>

            <Divider my="md" style={{ borderColor: '#2f2f2f' }} />
            <Button
                radius={12}
                onClick={handleSubmit}
                color="indigo"
                size="lg"
              >
                Submit
              </Button>
          </Paper>
        </Container>
      </PageChangeContainer>
    </>
  );
};

export default CreateJob;
