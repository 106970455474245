import { Paper, Skeleton } from "@mantine/core";
import React from "react";
import { motion } from "framer-motion";

const JobSkeleton = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 0.1 } }}
      className="mx-auto"
      style={{ maxWidth: 600, marginTop: 16 }}
    >
      <Paper radius={24} withBorder p="md" shadow="md">
        <Skeleton height={20} width="40%" mt={12} mb="sm" />
        <Skeleton height={14} mt={10} width="10%" radius="xl" mb="sm" />
        <Skeleton height={8} mb={10} width="30%" radius="xl" />
        <Skeleton height={8} mt={10} width="30%" radius="xl" />
        <Skeleton height={8} mt={10} width="70%" radius="xl" />
        <Skeleton height={8} mt={10} mb={12} radius="xl" />
        <Skeleton height={8} mt={10} mb={12} radius="xl" />
      </Paper>
    </motion.div>
  );
};

export default JobSkeleton;
