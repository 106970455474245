import { Badge } from "@mantine/core";
import React from "react";

const JobEmploymentTypeBadge = ({ job, largeSize }) => {
  return (
    <div style={{ paddingBottom: 8, paddingTop: 4 }}>
      {job?.job_employment_type === "Fulltime" && (
        <Badge size={largeSize && "lg"} color="grape">
          Full time
        </Badge>
      )}
      {job?.job_employment_type === "Parttime" && (
        <Badge size={largeSize && "lg"} color="cyan">
          Part time
        </Badge>
      )}
      {job?.job_employment_type === "Onetime" && (
        <Badge size={largeSize && "lg"} color="indigo">
          One time
        </Badge>
      )}
    </div>
  );
};
export default JobEmploymentTypeBadge;
