import * as React from "react";

const Wave = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
    <path
      fill="#141517"
      fillOpacity="1"
      d="M0,160L40,154.7C80,149,160,139,240,165.3C320,192,400,256,480,245.3C560,235,640,149,720,122.7C800,96,880,128,960,149.3C1040,171,1120,181,1200,186.7C1280,192,1360,192,1400,192L1440,192L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"
    ></path>
  </svg>
);

export default Wave;
