import React from "react";

import { AccountNavigation } from "../components/AccountComponents/AccountNavigation";

import "react-coinbase-commerce/dist/coinbase-commerce-button.css";
import Footer from "../components/Footer";

const UpdateProfile = () => {
  return (
    <>
      <div
        style={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ flexGrow: 1 }}>
          <AccountNavigation />
        </div>
      </div>
      <Footer />
    </>
  );
};
export default UpdateProfile;
