import React from "react";
import { Center, Container, Text, Timeline, Title } from "@mantine/core";
import { PageChangeContainer } from "../components/RouteComponents/PageChangeContainer";
import Footer from "../components/Footer";
import { Stack, Stack2, Stack3, Check } from "tabler-icons-react";

function AboutRoadmap() {
  return (
    <>
      <Container px="xl">
        <PageChangeContainer
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "80vh",
          }}
        >
          <Title>
            <Text
              py="xl"
              align="center"
              style={{ fontSize: 30, fontWeight: 700 }}
            >
              🚧 About the Project & Future Plans
            </Text>
          </Title>
          <Center py="xl">
            <Timeline color="indigo" active={0} bulletSize={26} lineWidth={2}>
              <Timeline.Item bullet={<Check size={16} />} title="Site Launch">
                <Text color="dimmed" size="sm">
                  We launched our site in March of 2024. 🎉

                 We aim to simplify earning cryptocurrency by offering a comprehensive ecosystem for job
                 seekers and employers to connect and transact in cryptocurrency.
                </Text>
              </Timeline.Item>

              <Timeline.Item title="What's Next?" bullet={<Stack2 size={16} />}>
                <Text color="dimmed" size="sm">
                  We'll be bringing out new features to facilitate and protect
                  users and their transactions.
                </Text>
                <Text color="dimmed" size="sm">
                  Have an idea for the site? Feel free to contact us! 
                </Text>
              </Timeline.Item>
            </Timeline>
          </Center>
        </PageChangeContainer>
      </Container>
      <Footer />
    </>
  );
}

export default AboutRoadmap;
