import { Jobs } from "./JobComponents/Jobs";
import { SearchComponent } from "./ThemedComponents/SearchComponent";
import { SearchResults } from "./SearchComponents/SearchResults";
import { Container } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseURL } from "../Utils/Constants";
import Footer from "../components/Footer";
import JobSkeleton from "./JobComponents/JobSkeleton";

const SearchSection = () => {
  const matches = useMediaQuery("(min-width: 768px)");
  const [searchInput, setSearchInput] = useState("");
  const [locationInput, setLocationInput] = useState("");

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);

  // Get current posts and length for pagination
  const indexOfLastJob = currentPage * postsPerPage;
  const indexOfFirstJob = indexOfLastJob - postsPerPage;
  const currentJob = data?.slice?.(indexOfFirstJob, indexOfLastJob);

  //useChip changes the endpoint for job category
  const [chipVaule, setChipValue] = useState("all");

  const searchItems = async () => {
    if (searchInput === "") {
      return;
    }
    setData([]);
    setError(false);
    setLoading(true);
    if (searchInput !== "") {
      try {
        const response = await axios(
          `${baseURL}/jobs/search/all ${searchInput}`
        );
        setData(response?.data);
      } catch (e) {
        setError(true);
      }
      setSearchInput("");
      setLocationInput("");
      setCurrentPage(1);
    }
    setLoading(false);
  };

  useEffect(() => {
    let source = axios.CancelToken.source();

    const axiosFetchChipSearch = async (url) => {
      setLoading(true);
      setError(false);
      try {
        const response = await axios.get(url, { cancelToken: source.token });
        setData(response?.data);
      } catch (e) {
        if (axios.isCancel(e)) {
          return;
        }
        setError(true);
      }
      setCurrentPage(1);
      setLoading(false);
    };
    axiosFetchChipSearch(`${baseURL}/jobs/search/${chipVaule}`);

    return () => source.cancel();
  }, [chipVaule]);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Container pt="xl">
          <SearchComponent
            matches={matches}
            locationInput={locationInput}
            setLocationInput={setLocationInput}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            searchItems={searchItems}
            chipVaule={chipVaule}
            setChipValue={setChipValue}
          />
          <SearchResults
            loading={loading}
            error={error}
            currentPage={currentPage}
            data={data}
          />

          {loading && (
            <>
              <JobSkeleton />
              <JobSkeleton />
              <JobSkeleton />
            </>
          )}
          {currentJob && !error && (
            <Jobs
              data={data}
              currentJob={currentJob}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              postsPerPage={postsPerPage}
              loading={loading}
              job={data}
            />
          )}
        </Container>
      </div>
      {!loading && <Footer />}
    </>
  );
};

export default SearchSection;
