import React, { useState } from "react";
import { Container, Navbar } from "react-bootstrap";
import {
  Text,
  Title,
  Burger,
  Collapse,
  Group,
  SimpleGrid,
} from "@mantine/core";
import { useHistory } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "@mantine/hooks";
import Twitter from "../images/Twitter";
import DarkModeButton from "./NavbarComponents/DarkModeButton";

export default function UserNavBar() {
  const { currentUser, logout } = useAuth();
  const location = useLocation();
  const matches = useMediaQuery("(max-width: 992px)");

  const [opened, setOpened] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);

  const history = useHistory();

  const pushToCreateJob = () => {
    history.push("/create-jobs");
    setOpened(false);
  };

  const pushToMyJobs = () => {
    history.push("/my-jobs");
    setOpened(false);
  };

  const pushToSignup = () => {
    history.push("/signup");
    setOpened(false);
  };

  const refresh = () => {
    if (currentUser) {
      history.push(`/search-job:uuid`);
    } else {
      history.push("/");
    }
    setOpened(false);
  };

  const pushToUpdate = () => {
    history.push("/update-profile");
    setOpened(false);
  };

  async function handleLogout() {
    await logout();
    history.push("/login");
    setOpened(false);
  }

  return (
    <>
      {matches ? (
        <>
          <Navbar expand="lg" fixed="sticky">
            <Text onClick={refresh}>
              <Title style={{ fontSize: 25, fontWeight: 700 }}>
                JobsOnBlocks
              </Title>
            </Text>
            <Group>
              <DarkModeButton />
              <Burger
                style={{ marginRight: 24 }}
                size="lg"
                ref={setReferenceElement}
                opened={opened}
                onClick={() => setOpened((o) => !o)}
              />
            </Group>
          </Navbar>
          <Collapse
            transitionDuration={100}
            transitionTimingFunction="linear"
            in={opened}
          >
            <SimpleGrid cols={1} spacing="xl">
              {location.pathname === "/" ? null : (
                <>
                  <Text
                    onClick={pushToCreateJob}
                    size="md"
                    align="center"
                    weight={500}
                  >
                    Post a job
                  </Text>
                  <Text
                    onClick={pushToMyJobs}
                    align="center"
                    size="md"
                    weight={500}
                  >
                    Manage Jobs
                  </Text>
                </>
              )}
              {!currentUser ? (
                <>
                  <Text
                    onClick={pushToSignup}
                    align="center"
                    size="md"
                    weight={500}
                  >
                    Log in / Sign up
                  </Text>
                </>
              ) : (
                <>
                  <Text
                    onClick={pushToUpdate}
                    align="center"
                    size="md"
                    weight={500}
                  >
                    Account
                  </Text>

                  <Text
                    onClick={handleLogout}
                    align="center"
                    size="md"
                    weight={500}
                    color="indigo"
                  >
                    Log out
                  </Text>
                </>
              )}
            </SimpleGrid>
          </Collapse>
        </>
      ) : (
        <Navbar fixed="sticky">
          <Container>
            {location.pathname === "/" ? (
              <Text onClick={refresh}>
                <Title
                  style={{ fontSize: 30, fontWeight: 700, cursor: "pointer" }}
                >
                  JobsOnBlocks
                </Title>
              </Text>
            ) : (
              <Group spacing="xl">
                <Text onClick={refresh}>
                  <Title
                    style={{ fontSize: 30, fontWeight: 700, cursor: "pointer" }}
                  >
                    JobsOnBlocks
                  </Title>
                </Text>
                <Text
                  style={{ cursor: "pointer" }}
                  onClick={pushToCreateJob}
                  size="md"
                  weight={500}
                >
                  Post a job
                </Text>

                <Text
                  style={{ cursor: "pointer" }}
                  onClick={pushToMyJobs}
                  size="md"
                  weight={500}
                >
                  Manage Jobs
                </Text>
              </Group>
            )}
            {!currentUser ? (
              <Group>
                <DarkModeButton />
                <Text
                  style={{ cursor: "pointer" }}
                  onClick={pushToSignup}
                  size="md"
                  weight={500}
                >
                  Log in / Sign up
                </Text>
              </Group>
            ) : (
              <Group>
                <DarkModeButton />
                <Text
                  style={{ cursor: "pointer" }}
                  onClick={pushToUpdate}
                  size="md"
                  weight={500}
                >
                  Account
                </Text>

                <Text
                  style={{ cursor: "pointer" }}
                  onClick={handleLogout}
                  size="md"
                  weight={500}
                  color="indigo"
                >
                  Log out
                </Text>
              </Group>
            )}
          </Container>
        </Navbar>
      )}
    </>
  );
}
