import { EditJobMenu } from "./EditJobMenu";
import VerifyedJobLogo from "../JobComponents/VerifyedJobLogo";
import { Drawer, Group, Paper, Text } from "@mantine/core";
import { useHover } from "@mantine/hooks";
import React, { useState } from "react";
import DrawerJobDisplay from "../DrawerComponents/DrawerJobDisplay";
import CoinJobIcons from "../ThemedComponents/CoinJobIcons";
import JobEmploymentTypeBadge from "./JobEmploymentTypeBadge";
import JobHireTypeBadge from "./JobHireTypeBadge";
import DOMPurify from 'dompurify';
import Moment from "react-moment";

import { PageChangeContainer } from "../RouteComponents/PageChangeContainer";

const Job = ({ job, editJob, onDelete }) => {
  const [open, setOpen] = useState(false);
  const { hovered, ref } = useHover();

  const handleJobClick = () => {
    setOpen(true);
  };

  return (
    <PageChangeContainer style={{ marginTop: 16, maxWidth: 600 }}>
      <Paper
        ref={ref}
        radius={24}
        withBorder
        p="lg"
        shadow={hovered || editJob ? "xl" : "md"}
        onClick={!editJob && handleJobClick}
      >
        <Group align noWrap position="apart">
          <Text size="xl" weight={600}>
            {job.title} <VerifyedJobLogo job={job} />
          </Text>
          {editJob ? (
            <Group>
              <CoinJobIcons job={job} />
              <EditJobMenu job={job} onDelete={onDelete} />
            </Group>
          ) : (
            <CoinJobIcons job={job} />
          )}
        </Group>
        <Text size="md">{job.name}</Text>
        <Group align spacing="xs" style={{ marginLeft: -1 }}>
          <JobHireTypeBadge job={job} />
          <JobEmploymentTypeBadge job={job} />
        </Group>
        <Text size="sm" color="gray">
          {!job.location ? "Remote 🌎" : job.location}
        </Text>
        <Text size="sm" color="gray">
          <Moment format="YYYY/MM/DD">{job.created_at}</Moment>
        </Text>
        <Text lineClamp={2} style={{ marginTop: 8, lineHeight: 1.15 }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(job.description) }}>
        </Text>
      </Paper>
      {!editJob && (
        <Drawer
          overlayOpacity={0.25}
          opened={open}
          onClose={() => setOpen(false)}
          position="right"
          padding="lg"
          size={600}
          lockScroll={true}
        >
          <Text
            style={{ marginTop: -44 }}
            pb="lg"
            align="center"
            size="xl"
            weight={600}
          >
            {job.title} <VerifyedJobLogo job={job} />
          </Text>
          <DrawerJobDisplay open={open} setOpen={setOpen} job={job} />
        </Drawer>
      )}
    </PageChangeContainer>
  );
};

export default Job;
